<template>
    <svg
        fill="none"
        height="14"
        viewBox="0 0 8 14"
        width="8"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M1 1L7 7L1 13" stroke="currentColor" />
    </svg>
</template>
