<template>
    <svg
        fill="none"
        height="17"
        viewBox="0 0 16 17"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.4856 2.48596V14.5143C1.4856 14.6469 1.53827 14.7741 1.63204 14.8678C1.72581 14.9616 1.85299 15.0143 1.9856 15.0143H14.0139C14.1465 15.0143 14.2737 14.9616 14.3675 14.8678C14.4612 14.7741 14.5139 14.6469 14.5139 14.5143V2.48596C14.5139 2.35335 14.4612 2.22618 14.3675 2.13241C14.2737 2.03864 14.1465 1.98596 14.0139 1.98596H1.9856C1.85299 1.98596 1.72581 2.03864 1.63204 2.13241C1.53827 2.22618 1.4856 2.35335 1.4856 2.48596ZM10.5043 2.98596H13.5137V5.99534H10.5043V2.98596ZM10.5043 6.99534H13.5137V10.0047H10.5043V6.99534ZM10.5043 11.0047H13.5137V14.0141H10.5043V11.0047ZM6.49497 2.98596H9.50435V5.99534H6.49497V2.98596ZM6.49497 6.99534H9.50435V10.0047H6.49497V6.99534ZM6.49497 11.0047H9.50435V14.0141H6.49497V11.0047ZM2.4856 2.98596H5.49497V5.99534H2.4856V2.98596ZM2.4856 6.99534H5.49497V10.0047H2.4856V6.99534ZM2.4856 11.0047H5.49497V14.0141H2.4856V11.0047Z"
            fill="currentColor"
        />
    </svg>
</template>
